import { useMutation, useQueryClient } from '@tanstack/vue-query'
import * as Sentry from '@sentry/vue'
import { type InjectionKey, inject, provide, ref } from 'vue'
import { usePostLoginRouting } from '../postLoginRouting'
import { type TreatyData, type TreatyError, type TreatyParams, useTreaty, useTreatyQueryFn } from '@/common/composables/useTreaty'
import { useUserStore } from '@/stores/userStore'
import { handleException } from '@/common/utils/helpers'

const isChangingOrganizationKey: InjectionKey<Ref<boolean>> = Symbol('isChangingOrganization')

export function provideChangeOrganizationState() {
  const isChangingOrganization = ref(false)
  provide(isChangingOrganizationKey, isChangingOrganization)
}

export function useChangeSelectedOrganizationMutation() {
  const queryClient = useQueryClient()
  const userStore = useUserStore()
  const changeOrganizationAPI = () => useTreaty().authentication.refresh.post
  type ChangeOrganizationAPI = ReturnType<typeof changeOrganizationAPI>
  const { redirectToBestRoute } = usePostLoginRouting()

  const isChangingOrganization = inject(isChangingOrganizationKey) || ref(false)

  const mutation = useMutation<TreatyData<ChangeOrganizationAPI>, TreatyError<ChangeOrganizationAPI>, TreatyParams<ChangeOrganizationAPI>>({
    mutationKey: ['changeOrganization'],
    mutationFn: params => useTreatyQueryFn(changeOrganizationAPI(), params),
    onMutate: () => {
      // TODO: I think that we need to route the user to a special page that says "switching organizations"
      //   this would allow us to avoid showing weird stuff happening on screen as we
      //   switch organizations
      isChangingOrganization.value = true
    },
    onSuccess: async () => {
      try {
        await userStore.getUser(true)
        // Some queries will refetch automatically, when we force a user refresh.
        // We don't want to cancel those refetches, but we'll invalidate all other queries.
        await queryClient.invalidateQueries(undefined, {
          cancelRefetch: false
        })
        nextTick(() => {
          redirectToBestRoute()
        })
      }
      catch (error) {
        console.error(error)
        Sentry.captureException(error)
        userStore.clearUser()
        await queryClient.invalidateQueries()
      }
      finally {
        isChangingOrganization.value = false
      }
    },
    onError: (error) => {
      isChangingOrganization.value = false
      handleException('Error changing organization', error)
    }
  })

  return {
    ...mutation,
    isChangingOrganization,
  }
}
