import type { ExtendedRouteConfig } from '@/router'

export const authenticationRoutes: ExtendedRouteConfig = {
  path: 'auth',
  meta: {
    layout: 'AppBarLayout',
  },
  children: [
    {
      path: 'callback',
      name: 'LoginVerify',
      component: () => import('./views/LoginVerify.vue'),
      beforeEnter: async (to, _from, next) => {
        return next()
      },
      meta: {
        allowAnonymous: true,
        tokenRedemption: true,
      },
    },
    {
      path: 'fine-grained-access/experimenting',
      name: 'FineGrainedAccessExperimenting',
      component: () => import('./FineGrainedAccessExperimenting.vue'),
    }
  ],
}
