import type { ExtendedRouteConfig } from '@/router'

export const usersAdminRoutes: ExtendedRouteConfig = {
  path: 'Users',
  children: [
    {
      path: '',
      name: 'AdminUsers',
      component: () => import('./views/Users.vue'),
    },
    {
      path: ':userId',
      name: 'AdminUser',
      component: () => import('./views/User.vue'),
      props: true,
    },
  ],
}
