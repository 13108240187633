import type { ExtendedRouteConfig } from '@/router';

export const userRoutes: ExtendedRouteConfig = {
  path: 'user',
  meta: {
    layout: 'AppBarLayout'
  },
  children: [
    {
      path: 'profile',
      name: 'Profile',
      component: () => import('./Profile.vue'),
    },
    {
      path: 'first-seen',
      name: 'FirstSeen',
      component: () => import('./FirstSeen.vue')
    }
  ]
};
