<script lang="ts" setup>
import LayoutRouter from './layouts/LayoutRouter.vue'
import { provideChangeOrganizationState } from './modules/authentication/composables/useChangeSelectedOrganizationMutation'

// Ensures that the change organization state is provided to the entire app
provideChangeOrganizationState()
</script>

<template>
  <LayoutRouter>
    <RouterView />
  </LayoutRouter>
</template>
