import { orgDashboardsAdminRoutes } from './modules/dashboards/orgDashboardsAdmin.routes'
import { orgAssetIQUploadsAdminRoutes } from './modules/uploads/orgAssetIQUploadsAdmin.routes'
import type { ExtendedRouteConfig } from '@/router'

export const orgAssetIQAdminRoutes: ExtendedRouteConfig = {
  path: '',
  component: () => import('./views/AssetIQAdmin.vue'),
  children: [
    {
      path: '',
      name: 'AdminOrganizationAssetIQ',
      redirect: { name: 'AdminOrganizationUploads' }
    },
    orgDashboardsAdminRoutes,
    orgAssetIQUploadsAdminRoutes
  ]
}
