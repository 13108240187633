import { RouterView } from 'vue-router'
import { FGA_ROLES } from '@back/plugins/fga/permissionSecurity.enums'
import { demoOrganizationsRoutes } from './modules/demoOrganizations/demoOrganizations.routes'
import { organizationAdminRoutes } from './modules/organizations/organizationsAdmin.routes'
import { usersAdminRoutes } from './modules/users/usersAdmin.routes'
import { marketAnalyzerRoutes } from './modules/marketAnalyzer/marketAnalyzer.routes'
import AdminNavigation from '@/modules/admin/components/AdminNavigation.vue'
import type { ExtendedRouteConfig } from '@/router'

export const adminRoutes: ExtendedRouteConfig = {
  path: 'admin',
  components: {
    drawer: AdminNavigation,
    nav: () => import('@/layouts/components/NavigationBar.vue'),
    default: RouterView,
  },
  meta: {
    layout: 'AppBarDrawerLayout',
    authorization: {
      value: [
        FGA_ROLES.developer.slug,
        FGA_ROLES.admin.slug,
      ],
    },
  },
  children: [
    {
      path: '',
      name: 'SystemAdmin',
      redirect: { name: 'AdminOrganizations' },
    },
    {
      path: 'Dashboard',
      name: 'AdminDashboard',
      component: () => import('@/modules/admin/views/Dashboard.vue'),
    },
    organizationAdminRoutes,
    usersAdminRoutes,
    demoOrganizationsRoutes,
    marketAnalyzerRoutes,
  ],
}
