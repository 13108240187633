import type { ExtendedRouteConfig } from '@/router'

export const orgRequestIQAdminRoutes: ExtendedRouteConfig = {
  path: 'RequestIQ',
  component: () => import('./views/RequestIQAdmin.vue'),
  children: [
    {
      name: 'AdminOrganizationRequestIQ',
      path: '',
      redirect: {
        name: 'AdminOrganizationRequestIQLocations'
      }
    },
    {
      path: 'locations',
      props: true,
      children: [
        {
          name: 'AdminOrganizationRequestIQLocations',
          path: '',
          props: true,
          component: () => import('./modules/locations/views/OrganizationLocationsAdmin.vue')
        },
        {
          name: 'AdminOrganizationRequestIQLocationDepartments',
          path: ':locationId/departments',
          props: true,
          component: () => import('./modules/locations/modules/departments/views/OrganizationLocationDepartmentsAdmin.vue')
        }
      ]
    },
    {
      name: 'AdminOrganizationRequestIQStandards',
      path: 'standards',
      component: () => import('./modules/standards/views/OrganizationRequestStandardsAdmin.vue')
    }
  ]
}
